import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import {
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Paper,
  Button,
  Stack,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Menu,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Pagination,
  IconButton,
  InputBase,
  Box,
  CircularProgress,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import Axios from "axios";
import Sidebar from "../../sidebar/sidebar";
import { companylogo } from "../../../assets/images";
import { BaseUrl } from "../../../Environment";
import { Undo } from "@material-ui/icons";
import moment from "moment";
import { FilterAlt, Check, Search } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e1f0fa",
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    cursor: "pointer",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const loanTableHeader = {
  loanId: "LoanId",
  firstName: "Borrower",
  loanAmount: "Lent",
  totalCollectionAmount: "Collected",
  totalPenaltyApplied: "Penalty",
  balanceAmount: "Balance",
  pendingDues: "Remaining Dues",
};

const collectionTableHeader = {
  srNo: "Sr. No.",
  dueDate: "Due Date",
  collectedDate: "Collected Date",
  openingBalance: "Opening Balance",
  Installment: "Installment Amount",
  collectionAmount: "Collected",
  closingBalance: "Closing Balance",
  PenaltyApplied: "Penalty",
};

const LoanWiseReport = () => {
  let loggedinUser = JSON.parse(localStorage.getItem("loggedinUser"));
  let companyCountry = JSON.parse(localStorage.getItem("companyCountry"));

  const [reportTableData, setReportTableData] = useState([]);
  const [totalDataBox, setTotalDataBox] = useState([]);
  const [loanClnData, setLoanClnData] = useState([]);
  const [loanStatus, setLoanStatus] = useState("Open");
  const [loanPaymentStatus, setLoanPaymentStatus] = useState("All");
  const [loanTenure, setLoanTenure] = useState("All");
  const [blockDatas, setBlockDatas] = useState({});
  const [loanId, setLoanId] = useState("");
  const [number, setNumber] = useState("");
  const [daysWeeksMonths, setDaysWeeksMonths] = useState("days");
  const [greaterLesser, setGreaterLesser] = useState("lesser");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [gotFullData, setGotFullData] = useState(false);
  const [fullReport, setFullReport] = useState(false);
  const [selectedBorrower, setSelectedBorrower] = useState("");
  const [currency, setCurrency] = useState(companyCountry?.symbol);
  const [userType, setUserType] = useState(loggedinUser?.userType);
  const [areaLine, setAreaLine] = useState("");
  const [dataAreaLine, setDataAreaLine] = useState([]);
  const [cAgentId, setCAgentId] = useState("");
  const [cAgentData, setCAgentData] = useState([]);
  const [selectedVal, setSelectedVal] = useState(null);
  const [filterCount, setFilterCount] = useState(null);
  const [collectionModal, setCollectionModal] = useState(false);
  const [search, setSearch] = useState("");

  // ======== Filter modal open/close ========
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const openFilter = Boolean(anchorElFilter);
  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };

  // ======== Filter collection agent modal open/close ========
  const [anchorElFilterCA, setAnchorElFilterCA] = useState(null);
  const openFilterCA = Boolean(anchorElFilterCA);
  const handleClickFilterCA = (event) => {
    setAnchorElFilterCA(event.currentTarget);
  };
  const handleCloseFilterCA = () => {
    setAnchorElFilterCA(null);
  };

  // ======== Filter area/line modal open/close ========
  const [anchorElFilterAL, setAnchorElFilterAL] = useState(null);
  const openFilterAL = Boolean(anchorElFilterAL);
  const handleClickFilterAL = (event) => {
    setAnchorElFilterAL(event.currentTarget);
  };
  const handleCloseFilterAL = () => {
    setAnchorElFilterAL(null);
  };

  // ======== Filter age of loan modal open/close ========
  const [anchorElFilterAOL, setAnchorElFilterAOL] = useState(null);
  const openFilterAOL = Boolean(anchorElFilterAOL);
  const handleClickFilterAOL = (event) => {
    setAnchorElFilterAOL(event.currentTarget);
  };
  const handleCloseFilterAOL = () => {
    setAnchorElFilterAOL(null);
  };

  // ======== Filter long pending dues modal open/close ========
  const [anchorElFilterLPD, setAnchorElFilterLPD] = useState(null);
  const openFilterLPD = Boolean(anchorElFilterLPD);
  const handleClickFilterLPD = (event) => {
    setAnchorElFilterLPD(event.currentTarget);
  };
  const handleCloseFilterLPD = () => {
    setAnchorElFilterLPD(null);
  };

  function processLoanData(data) {
    const result = {};

    data.forEach((entry) => {
      const loanIDs = entry.loanId;
      const customer = entry.customer;
      const collectorId = entry.collectorId;
      const loanCollections = entry?.loanCollections
        ?.filter((data) => data?.collectionStatus != null)
        ?.map((data) => ({
          dueDate: data?.dueDate,
          collectedDate: data?.collectedDate,
          openingBalance: parseFloat(data?.openingBalance),
          Installment: parseFloat(data?.Installment),
          collectionAmount: parseFloat(data?.collectionAmount),
          closingBalance: parseFloat(data?.closingBalance),
          PenaltyApplied: parseFloat(data?.PenaltyApplied),
        }));

      if (!result[loanIDs]) {
        result[loanIDs] = {
          loanId: loanIDs,
          customer,
          collectorId,
          loanTenureType: entry?.loanTenureType,
          loanTenureVal: entry?.loanTenure,
          loanAmount: parseFloat(entry?.totalAmountToCollect),
          loanStatus: entry?.loanStatus,
          loanPaymentStatus: entry?.loanPaymentStatus,
          collectedDate: entry?.collectedDate,
          createdAt: entry?.createdAt,
          startDate: entry?.startDate,
          totalCollectionAmount: 0,
          totalPenaltyApplied: 0,
          pendingDues: 0,
          balanceAmount: 0,
          loanCollections,
        };
      }

      if (loanCollections) {
        result[loanIDs].pendingDues =
          entry?.loanTenure - loanCollections.length;
        result[loanIDs].totalCollectionAmount = loanCollections.reduce(
          (total, collection) => {
            return total + parseFloat(collection.collectionAmount);
          },
          0
        );
        result[loanIDs].totalPenaltyApplied = loanCollections.reduce(
          (total, collection) => {
            return total + parseFloat(collection.PenaltyApplied ?? 0);
          },
          0
        );
        result[loanIDs].balanceAmount =
          parseFloat(loanCollections?.slice(-1)[0]?.closingBalance) ||
          parseFloat(entry?.totalAmountToCollect);
      }
    });

    return Object.values(result);
  }

  function processBlockData(data) {
    const result = {};

    data.forEach((entry) => {
      const loanIDs = entry.loanId;
      const loanCollections = entry?.loanCollections?.filter(
        (data) => data?.collectionStatus != null
      );

      if (!result[loanIDs]) {
        result[loanIDs] = {
          loanId: loanIDs,
          loanAmount: parseFloat(entry?.totalAmountToCollect),
          totalCollectionAmount: 0,
          totalPenaltyApplied: 0,
          balanceAmount: 0,
        };
      }

      if (loanCollections) {
        result[loanIDs].pendingDues =
          entry?.loanTenure - loanCollections.length;
        result[loanIDs].totalCollectionAmount = loanCollections.reduce(
          (total, collection) => {
            return total + parseFloat(collection.collectionAmount);
          },
          0
        );
        result[loanIDs].totalPenaltyApplied = loanCollections.reduce(
          (total, collection) => {
            return total + parseFloat(collection.PenaltyApplied ?? 0);
          },
          0
        );
        result[loanIDs].balanceAmount =
          parseFloat(loanCollections?.slice(-1)[0]?.closingBalance) ||
          parseFloat(entry?.totalAmountToCollect);
      }
    });

    return Object.values(result);
  }

  function calculateDate(number, unit) {
    const currentDate = new Date();

    if (unit === "days") {
      currentDate.setDate(currentDate.getDate() - number);
    } else if (unit === "weeks") {
      currentDate.setDate(currentDate.getDate() - number * 7);
    } else if (unit === "months") {
      currentDate.setMonth(currentDate.getMonth() - number);
    }

    return currentDate;
  }

  const getVerifiedCustomers = async () => {
    let loggedinUser = localStorage.getItem("loggedinUserCompany");

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    };
    Axios
      .get(
        BaseUrl +
          "/customer/getVerifiedCustomers?name=&companyId=" +
          loggedinUser,
        {
          headers: headers,
        }
      )
      .then((resp) => {
        if (resp.status === 200) {
          const filteredAL = resp.data?.filter((val) => val?.areaLine != null)?.filter((val) => val?.areaLine != "")?.map((data) => data?.areaLine);
          setDataAreaLine([...new Set(filteredAL)]);
        }
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const getLoansData = async () => {
    try {
      setLoading(true);

      const token = JSON.parse(localStorage.getItem("token"));
      const companyId = localStorage.getItem("loggedinUserCompany");
      let collectorId = loggedinUser?.userId;

      const headers = {
        Authorization: "Bearer " + token,
      };
      const mainDate = calculateDate(parseInt(number), daysWeeksMonths);

      const payLoad = {
        companyId,
        areaLine,
        collectorId: cAgentId ? cAgentId : collectorId,
        caFilter: cAgentId ? true : false,
        mainDate,
        greaterLesser,
        loanPaymentStatus,
        loanStatus,
        radioValue: selectedVal,
        loanTenureType: loanTenure,
        limit: 20,
        page,
        fullReport,
        userType,
        totalDataBox,
        search
      };

      const res = await Axios.post(
        `${BaseUrl}/loan/getLoanwiseReport`,
        payLoad,
        {
          headers,
        }
      );


      const { data: rawData } = res;

      const loanData = processLoanData(rawData?.result);
      const blockData =
        totalDataBox?.length > 0
          ? processBlockData(totalDataBox)
          : processBlockData(rawData?.totalData);

      if(loanData?.length > 0){
        setReportTableData(loanData);
      } else {
        setReportTableData([]);
      }
      setBlockDataFn(blockData);
      setTotalDataBox(res?.data?.totalData);
      setTotalPages(Math.ceil(parseFloat(res?.data?.totalRecords) / 20));
      if (fullReport) {
        setGotFullData(true);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const getAllUsers = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    const companyId = localStorage.getItem("loggedinUserCompany");

    const headers = {
      Authorization: "Bearer " + token,
    };

    Axios.get(`${BaseUrl}/user/getAllUsers?userName=&companyId=${companyId}`, {
      headers,
    })
      .then((res) => {
        const Response = res.data?.result;
        setCAgentData(
          userType === "superAdmin"
            ? Response
            : Response?.filter((item) => item?.userId == loggedinUser?.userId)
        );
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  useEffect(() => {
    getVerifiedCustomers();
    getAllUsers();
  }, []);

  useEffect(() => {
    getLoansData();
  }, [
    page,
    loanStatus,
    loanPaymentStatus,
    loanTenure,
    fullReport,
    selectedVal,
    areaLine,
    cAgentId,
  ]);

  useEffect(() => {
    if (search == "") {
      getLoansData();
    }
  }, [search]);

  const setBlockDataFn = (dataArr) => {
    const sumLoanAmt = dataArr?.reduce(
      (acc, value) => acc + parseFloat(value?.loanAmount),
      0
    );
    const sumCollectionAmt = dataArr?.reduce(
      (acc, value) => acc + parseFloat(value?.totalCollectionAmount),
      0
    );
    const sumPenaltyAmt = dataArr?.reduce(
      (acc, value) => acc + parseFloat(value?.totalPenaltyApplied),
      0
    );
    const sumBalanceAmt = dataArr?.reduce(
      (acc, value) => acc + parseFloat(value?.balanceAmount),
      0
    );

    setBlockDatas({
      sumLoanAmt,
      sumCollectionAmt,
      sumPenaltyAmt,
      sumBalanceAmt,
    });
  };

  const fourBlockData = [
    {
      value: blockDatas?.sumLoanAmt ?? 0,
      heading: "Total Loan Amt",
      color: "#faebfe",
      currency: currency,
    },
    {
      value: blockDatas?.sumCollectionAmt ?? 0,
      heading: "Total Collected Amt",
      color: "#fbedea",
      currency: currency,
    },
    {
      value: blockDatas?.sumPenaltyAmt ?? 0,
      heading: "Total Penalty Amt",
      color: "#f1f0f5",
      currency: currency,
    },
    {
      value: blockDatas?.sumBalanceAmt ?? 0,
      heading: "Total Balance Amt",
      color: "#ebf1fc",
      currency: currency,
    },
  ];

  const LoanStatusFilter = [
    { label: "Open", value: "Open" },
    { label: "Closed", value: "Closed" },
    { label: "Cancelled", value: "Cancelled" },
    { label: "All", value: "All" },
  ];

  const PaymentStatusFilter = [
    { label: "Due", value: "Due" },
    { label: "OverDue", value: "OverDue" },
    { label: "All", value: "All" },
  ];

  const SequenceFilter = [
    { value: "onetime", label: "Onetime" },
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "All", label: "All" },
  ];

  const handleChangeLoanStatus = (event, val) => {
    setTotalDataBox([]);
    setPage(1);
    setLoanStatus(val);
  };

  const handleChangePaymentStatus = (event, val) => {
    setTotalDataBox([]);
    setPage(1);
    setLoanPaymentStatus(val);
  };

  const handleChangeSequence = (event, val) => {
    setTotalDataBox([]);
    setPage(1);
    setLoanTenure(val);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const [sortedData, setSortedData] = useState([]);
  const [sortedDataCln, setSortedDataCln] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  // Handle sorting by a specific column
  const handleSortNew = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    if (reportTableData?.length > 0) {
      let sortedArray = [...reportTableData];

      if (sortConfig.key) {
        const aa = sortedArray?.sort((a, b) => {
          const aVal = sortConfig.key === "firstName" ? a?.customer[sortConfig.key] : a[sortConfig.key];
          const bVal = sortConfig.key === "firstName" ? b?.customer[sortConfig.key] : b[sortConfig.key];

          // Sorting logic for dates in 'DD/MM/YYYY' format
          if (sortConfig.key === "date") {
            const dateA = moment(aVal, "DD/MM/YYYY");
            const dateB = moment(bVal, "DD/MM/YYYY");
            return sortConfig.direction === "asc"
              ? +dateA - +dateB
              : +dateB - +dateA;
          }

          // Numeric sorting
          if (typeof aVal === "number" && typeof bVal === "number") {
            return sortConfig.direction === "asc" ? aVal - bVal : bVal - aVal;
          }

          // String sorting
          if (typeof aVal === "string" && typeof bVal === "string") {
            return sortConfig.direction === "asc"
              ? aVal?.localeCompare(bVal)
              : bVal?.localeCompare(aVal);
          }

          return 0;
        });
      }
      setSortedData(sortedArray);
    } else {
      setSortedData([]);
    }

    if (loanClnData?.length > 0) {
      let sortedArrayCln = [...loanClnData];

      if (sortConfig.key) {
        const aa = sortedArrayCln?.sort((a, b) => {
          const aVal = a[sortConfig.key];
          const bVal = b[sortConfig.key];

          // Sorting logic for dates in 'DD/MM/YYYY' format
          if (sortConfig.key === "date") {
            const dateA = moment(aVal, "DD/MM/YYYY");
            const dateB = moment(bVal, "DD/MM/YYYY");
            return sortConfig.direction === "asc"
              ? +dateA - +dateB
              : +dateB - +dateA;
          }

          // Numeric sorting
          if (typeof aVal === "number" && typeof bVal === "number") {
            return sortConfig.direction === "asc" ? aVal - bVal : bVal - aVal;
          }

          // String sorting
          if (typeof aVal === "string" && typeof bVal === "string") {
            return sortConfig.direction === "asc"
              ? aVal?.localeCompare(bVal)
              : bVal?.localeCompare(aVal);
          }

          return 0;
        });
      }
      setSortedDataCln(sortedArrayCln);
    } else {
      setSortedDataCln([]);
    }
  }, [sortConfig, reportTableData, loanClnData]);

  return (
    <>
      <Sidebar />
      <div style={{ marginLeft: "73px", height: "100vh" }}>
        {loading ? (
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Grid container direction={"column"}>
            <Grid item sx={{ backgroundColor: "#fff" }}>
              <div style={{ margin: 5 }}>
                <img src={companylogo} width={200} height={40} alt="logo" />
              </div>
            </Grid>
            <Grid item container paddingX={4} paddingY={2}>
              <Grid item xs={12} sm={6} md={4} lg={3} marginTop={2}>
                <ToggleButtonGroup
                  color="primary"
                  value={loanStatus}
                  exclusive
                  onChange={handleChangeLoanStatus}
                  aria-label="Platform"
                  size="small"
                >
                  {LoanStatusFilter?.map((filter, i) => (
                    <ToggleButton key={i} value={filter?.value}>
                      {filter?.label}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} marginTop={2}>
                <ToggleButtonGroup
                  color="primary"
                  value={loanPaymentStatus}
                  exclusive
                  onChange={handleChangePaymentStatus}
                  aria-label="Platform"
                  size="small"
                >
                  {PaymentStatusFilter?.map((filter, i) => (
                    <ToggleButton key={i} value={filter?.value}>
                      {filter?.label}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} marginTop={2}>
                <ToggleButtonGroup
                  color="primary"
                  value={loanTenure}
                  exclusive
                  onChange={handleChangeSequence}
                  aria-label="Platform"
                  size="small"
                >
                  {SequenceFilter?.map((filter, i) => (
                    <ToggleButton key={i} value={filter?.value}>
                      {filter?.label}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} marginTop={2}>
                <Badge badgeContent={filterCount} color="primary">
                  <Button
                    id="filter-button"
                    aria-controls={openFilter ? "filter-basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openFilter ? "true" : undefined}
                    onClick={handleClickFilter}
                    variant="outlined"
                    endIcon={<FilterAlt />}
                  >
                    Filter
                  </Button>
                </Badge>
              </Grid>
              <Menu
                id="filter-basic-menu"
                anchorEl={anchorElFilter}
                open={openFilter}
                onClose={handleCloseFilter}
                MenuListProps={{
                  "aria-labelledby": "filter-button",
                  style: {
                    backgroundColor: "#2b3d63",
                    color: "#fff",
                    boxShadow: "#122344 1px 0px 5px 1px",
                  },
                }}
              >
                <MenuItem
                  id="filter-button-ca"
                  aria-controls={
                    openFilterCA ? "filter-basic-menu-ca" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openFilterCA ? "true" : undefined}
                  onClick={handleClickFilterCA}
                >
                  Collection Agent&nbsp;{cAgentId && <Check />}
                </MenuItem>
                <MenuItem
                  id="filter-button-al"
                  aria-controls={
                    openFilterAL ? "filter-basic-menu-al" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openFilterAL ? "true" : undefined}
                  onClick={handleClickFilterAL}
                >
                  Area/Line &nbsp;{areaLine && <Check />}
                </MenuItem>
                <MenuItem
                  id="filter-button-aol"
                  aria-controls={
                    openFilterAOL ? "filter-basic-menu-aol" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openFilterAOL ? "true" : undefined}
                  onClick={handleClickFilterAOL}
                >
                  Age of Loan&nbsp;{selectedVal == "3" && <Check />}
                </MenuItem>
                <MenuItem
                  id="filter-button-lpd"
                  aria-controls={
                    openFilterLPD ? "filter-basic-menu-lpd" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openFilterLPD ? "true" : undefined}
                  onClick={handleClickFilterLPD}
                >
                  Long pending dues&nbsp;{selectedVal == "4" && <Check />}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (selectedVal != "1") {
                      setFilterCount((val) => val + 1);
                    }
                    setSelectedVal("1");
                    setTotalDataBox([]);
                    setPage(1);
                    getLoansData();
                    handleCloseFilter();
                  }}
                >
                  Loans beyond the total Loan Tenure&nbsp;
                  {selectedVal == "1" && <Check />}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setFilterCount(null);
                    setNumber("");
                    setAreaLine("");
                    setCAgentId("");
                    setDaysWeeksMonths("days");
                    setGreaterLesser("lesser");
                    setSelectedVal(null);
                    setLoanStatus("Open");
                    setLoanPaymentStatus("All");
                    setLoanTenure("All");
                    setTotalDataBox([]);
                    handleCloseFilter();
                    getLoansData();
                  }}
                >
                  Reset
                </MenuItem>
              </Menu>
              <Menu
                id="filter-basic-menu-ca"
                anchorEl={anchorElFilterCA}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                style={{minWidth: "200px", minHeight: "400px", overflow: "auto"}}
                open={openFilterCA}
                onClose={handleCloseFilterCA}
                MenuListProps={{
                  "aria-labelledby": "filter-button-ca",
                }}
              >
                {cAgentData?.map((option) => (
                  <MenuItem
                    key={option?.userId}
                    onClick={() => {
                      if (!cAgentId) {
                        setFilterCount((val) => val + 1);
                      }
                      setTotalDataBox([]);
                      setPage(1);
                      // setAreaLine("");
                      setCAgentId(option?.userId);
                      handleCloseFilterCA();
                      handleCloseFilter();
                    }}
                  >
                    {option.firstName + " " + option.lastName}&nbsp;
                    {cAgentId === option?.userId && <Check />}
                  </MenuItem>
                ))}
              </Menu>
              <Menu
                id="filter-basic-menu-al"
                anchorEl={anchorElFilterAL}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                style={{minWidth: "200px", minHeight: "400px", overflow: "auto"}}
                open={openFilterAL}
                onClose={handleCloseFilterAL}
                MenuListProps={{
                  "aria-labelledby": "filter-button-al",
                }}
              >
                {dataAreaLine?.map((option, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      if (!areaLine) {
                        setFilterCount((val) => val + 1);
                      }
                      setAreaLine(option);
                      handleCloseFilterAL();
                      handleCloseFilter();
                      getLoansData();
                    }}
                  >
                    {option}&nbsp;{areaLine === option && <Check />}
                  </MenuItem>
                ))}
              </Menu>
              <Menu
                id="filter-basic-menu-aol"
                anchorEl={anchorElFilterAOL}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openFilterAOL}
                onClose={handleCloseFilterAOL}
                MenuListProps={{
                  "aria-labelledby": "filter-button-aol",
                }}
              >
                <Stack spacing={2} padding={1}>
                  <FormControl sx={{ m: 1 }} fullWidth size="small">
                    <InputLabel id="greater-lesser">Greater/Lesser</InputLabel>
                    <Select
                      labelId="greater-lesser"
                      id="greaterLesserId"
                      value={greaterLesser}
                      label="Greater/Lesser"
                      onChange={(e) => {
                        // setSelectedVal(null);
                        setGreaterLesser(e.target.value);
                        handleCloseFilterAOL();
                      }}
                    >
                      <MenuItem value={"greater"}>Greater than</MenuItem>
                      <MenuItem value={"lesser"}>Less than</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Number..."
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      setNumber(e.target?.value);
                    }}
                    sx={{ m: 1 }}
                  />
                  <FormControl sx={{ m: 1 }} fullWidth size="small">
                    <InputLabel id="days-weeks-months">
                      Days/Weeks/Months
                    </InputLabel>
                    <Select
                      labelId="days-weeks-months"
                      id="daysWeeksMonthsId"
                      value={daysWeeksMonths}
                      label="Days/Weeks/Months"
                      onChange={(e) => {
                        // setSelectedVal(null);
                        setDaysWeeksMonths(e.target.value);
                        handleCloseFilterAOL();
                      }}
                    >
                      <MenuItem value={"days"}>Days</MenuItem>
                      <MenuItem value={"weeks"}>Weeks</MenuItem>
                      <MenuItem value={"months"}>Months</MenuItem>
                    </Select>
                  </FormControl>
                  <Button
                    sx={{ maxWidth: 150 }}
                    onClick={() => {
                      if (selectedVal != "3") {
                        setFilterCount((val) => val + 1);
                      }
                      handleCloseFilterAOL();
                      handleCloseFilter();
                      setSelectedVal("3");
                      setTotalDataBox([]);
                      setPage(1);
                      getLoansData();
                    }}
                    variant="contained"
                  >
                    Proceed
                  </Button>
                </Stack>
              </Menu>
              <Menu
                id="filter-basic-menu-lpd"
                anchorEl={anchorElFilterLPD}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openFilterLPD}
                onClose={handleCloseFilterLPD}
                MenuListProps={{
                  "aria-labelledby": "filter-button-lpd",
                }}
              >
                <Stack spacing={2} padding={1}>
                  <TextField
                    id="outlined-basic"
                    label="Number..."
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      setNumber(e.target?.value);
                    }}
                    sx={{ m: 1 }}
                  />
                  <FormControl sx={{ m: 1 }} fullWidth size="small">
                    <InputLabel id="days-weeks-months">
                      Days/Weeks/Months
                    </InputLabel>
                    <Select
                      labelId="days-weeks-months"
                      id="daysWeeksMonthsId"
                      value={daysWeeksMonths}
                      label="Days/Weeks/Months"
                      onChange={(e) => {
                        // setSelectedVal(null);
                        setDaysWeeksMonths(e.target.value);
                        handleCloseFilterLPD();
                      }}
                    >
                      <MenuItem value={"days"}>Days</MenuItem>
                      <MenuItem value={"weeks"}>Weeks</MenuItem>
                      <MenuItem value={"months"}>Months</MenuItem>
                    </Select>
                  </FormControl>
                  <Button
                    sx={{ maxWidth: 150 }}
                    onClick={() => {
                      if (selectedVal != "4") {
                        setFilterCount((val) => val + 1);
                      }
                      handleCloseFilterLPD();
                      handleCloseFilter();
                      setSelectedVal("4");
                      setTotalDataBox([]);
                      setPage(1);
                      getLoansData();
                    }}
                    variant="contained"
                  >
                    Proceed
                  </Button>
                </Stack>
              </Menu>
            </Grid>
            <Grid item container paddingX={4} paddingY={2}>
              {fourBlockData?.map((data, i) => {
                return (
                  <Grid key={i} item xs={12} sm={6} md={4} lg={3} marginTop={2}>
                    <Card
                      elevation={3}
                      sx={{
                        marginRight: 5,
                        minHeight: 130,
                        borderRadius: 3,
                        backgroundColor: "#fff",
                      }}
                    >
                      <CardHeader
                        title={
                          <div style={{ fontSize: 15 }}>{data?.heading}</div>
                        }
                      />
                      <CardContent>
                        <CardHeader
                          title={
                            <div style={{ fontSize: 20 }}>
                              {(data.value ? data.value : "0") +
                                (data.currency ? " " + data.currency : "")}
                            </div>
                          }
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
            {loanId ? null : (
              <Box paddingX={4} paddingY={2}>
                <Grid item container >
                  <Grid item xs={12} sm={12} md={4} >
                    <p className="Fonts">NOTE: Click on record to see details</p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}></Grid>
                  <Grid item xs={12} sm={12} md={4} >
                    <Paper
                      component="form"
                      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                    >
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search Google Maps"
                        inputProps={{ 'aria-label': 'search google maps' }}
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                      />
                      <IconButton type="button" sx={{ p: '10px' }} onClick={getLoansData} aria-label="search">
                        <Search />
                      </IconButton>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            )}

            <Grid item container paddingX={4} paddingY={2}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          {Object.keys(loanTableHeader).map((property) => (
                            <StyledTableCell key={property}>
                              <TableSortLabel
                                active={sortConfig.key === property}
                                direction={
                                  sortConfig.key === property ? sortConfig.direction : "asc"
                                }
                                onClick={() => handleSortNew(property)}
                              >
                                {loanTableHeader[property]}
                              </TableSortLabel>
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sortedData?.map((data, index) => (
                          <StyledTableRow
                            key={index}
                            onClick={() => {
                              setLoanId(data?.loanId);
                              setSelectedBorrower(
                                data?.customer?.firstName +
                                  " " +
                                  data?.customer?.lastName
                              );
                              setBlockDataFn([
                                {
                                  loanAmount: parseFloat(data?.loanAmount),
                                  totalCollectionAmount:
                                    data?.totalCollectionAmount,
                                  totalPenaltyApplied:
                                    data?.totalPenaltyApplied,
                                  balanceAmount: parseFloat(data?.balanceAmount),
                                },
                              ]);
                              setLoanClnData(data?.loanCollections);
                              setCollectionModal(true);
                            }}
                          >
                            <StyledTableCell>{data?.loanId}</StyledTableCell>
                            <StyledTableCell>
                              {
                                <Stack>
                                  <div>
                                    {data?.customer?.firstName +
                                      " " +
                                      data?.customer?.lastName}
                                  </div>
                                  {data?.customer?.cust_id ? (
                                    <div>({data?.customer?.cust_id})</div>
                                  ) : null}
                                </Stack>
                              }
                            </StyledTableCell>
                            <StyledTableCell>
                              {data?.loanAmount}
                            </StyledTableCell>
                            <StyledTableCell>
                              {data?.totalCollectionAmount}
                            </StyledTableCell>
                            <StyledTableCell>
                              {data?.totalPenaltyApplied}
                            </StyledTableCell>
                            <StyledTableCell>
                              {data?.balanceAmount}
                            </StyledTableCell>
                            <StyledTableCell>
                              {data?.pendingDues}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    size="large"
                    style={{ marginTop: 10 }}
                  />
            </Grid>
          </Grid>
        )}
      </div>
      <Dialog
        open={collectionModal}
        fullWidth={true}
        maxWidth="lg"
        onClose={() => {
          setLoanId("");
          setBlockDataFn(reportTableData);
          setCollectionModal(false)
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {`Loan Collection Table | Borrower's Name: ${selectedBorrower}, Loan ID: ${loanId}`}
        </DialogTitle>
        <DialogContent>
          <Grid item container paddingX={4} paddingY={2}>
            {fourBlockData?.map((data, i) => {
              return (
                <Grid key={i} item xs={12} sm={6} md={4} lg={3} marginTop={2}>
                  <Card
                    elevation={3}
                    sx={{
                      marginRight: 5,
                      minHeight: 130,
                      borderRadius: 3,
                      backgroundColor: "#fff",
                    }}
                  >
                    <CardHeader
                      title={
                        <div style={{ fontSize: 15 }}>{data?.heading}</div>
                      }
                    />
                    <CardContent>
                      <CardHeader
                        title={
                          <div style={{ fontSize: 20 }}>
                            {(data.value ? data.value : "0") +
                              (data.currency ? " " + data.currency : "")}
                          </div>
                        }
                      />
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          {loanId ? (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          {Object.keys(collectionTableHeader).map((property) => (
                            <StyledTableCell key={property}>
                              <TableSortLabel
                                active={sortConfig.key === property}
                                direction={
                                  sortConfig.key === property ? sortConfig.direction : "asc"
                                }
                                onClick={() => handleSortNew(property)}
                              >
                                {collectionTableHeader[property]}
                              </TableSortLabel>
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sortedDataCln?.map((data, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell>{index + 1}</StyledTableCell>
                            <StyledTableCell>
                              {moment(data?.dueDate).format("DD/MM/YYYY")}
                            </StyledTableCell>
                            <StyledTableCell>
                              {moment(data?.collectedDate).format("DD/MM/YYYY")}
                            </StyledTableCell>
                            <StyledTableCell>
                              {data?.openingBalance}
                            </StyledTableCell>
                            <StyledTableCell>{data?.Installment}</StyledTableCell>
                            <StyledTableCell>
                              {data?.collectionAmount}
                            </StyledTableCell>
                            <StyledTableCell>
                              {data?.closingBalance}
                            </StyledTableCell>
                            <StyledTableCell>
                              {data?.PenaltyApplied}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
            ) : (
              <></> 
            )
          }
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setLoanId("");
              setBlockDataFn(reportTableData);
              setCollectionModal(false)
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withNamespaces()(LoanWiseReport);
